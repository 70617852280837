<template>
    <div>
        <div class="row">
            <div class="col-12 bg-dark ">

            </div>
        </div>
        <headerDesign v-if="editor" name="Client Enrollment"
                      logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                      class="mb-4"></headerDesign>
        <h2 v-else class="border-bottom  p-2 mb-3 " > Client Enrollment</h2>
        <div style="background-color:white; padding:2%">
            <div class="row listing" v-if="!editor" >
                <div class="col-12">

                    <div >

                        <div class="row">
                            <div class="col-12">
                                <div class="form-inline">
                                    <div class="form-group mr-1">
                                        <input v-on:keyup.enter="search" type="text" v-model="filters.search"
                                               class="form-control" placeholder="Keyword Search"/>
                                    </div>
                                    <div class="form-group mr-1">
                                        <select v-on:keyup.enter="search" v-model="filters.status" class="form-control">
                                            <option value="" selected>-- Any Status --</option>
                                            <option value="active">Active</option>
                                            <option value="disabled">Disabled</option>
                                        </select>
                                    </div>
                                    <button type="button" class="btn btn-secondary btnSearch mr-1" style="background-color: #383838" @click="search"><i class="fa fa-search" title="Search"> <span> Search Clients</span></i></button>
                                    <button type="button" class="btn btn-success btnSearch" @click="create"><i class="fa fa-plus" title="New"></i> <span>Add Clients</span></button>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <b-table :fields="columnFields" :items="items.data" v-if="items.data.length > 0 ">

                            <template v-slot:cell(Status)="row">
                                <b class="d-flex align-content-center text-white" :class="row.item.Status === 'active' ? 'text-success': 'text-danger'">{{ row.item.Status }}</b>
                            </template>

                            <template v-slot:cell(Actions)="row">
                                <button class="btn btn-info" v-b-tooltip.hover.top title="Edit Client" @click="edit(row.item)" ><span class="fa fa-pencil" title="View"></span></button>
                                <b-button class="btn btn-warning ml-1" v-b-tooltip.hover.top title="Configure Products" :to="{ path: '/product/management', query: { client_id: row.item._id } }"><span class="fa fa-cog" style="color: white;"></span></b-button>
                            </template>

                        </b-table>


                        <div class="row" v-else>
                            <!--                        <div class="col-12">-->
                            <!--                            <h4 class="text-center">NO DATA FOUND</h4>-->
                            <!--                        </div>-->
                            <div class="col-12 ">
                                <div class="  text-center m-5">
                                    <b-spinner label="Loading..." class="text-center"></b-spinner>
                                </div>
                                <div class="text-center mb-5">
                                    <strong>Loading...</strong>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="padding: 20px;">
                            <div class="col-12 col-sm-9">
                                <v-pagination
                                    v-model="page"
                                    :pageCount="Math.ceil(count/max)"
                                    :classes="bootstrapPaginationClasses"
                                    :labels="customLabels"
                                    @input="search"></v-pagination>
                            </div>
                            <div class="col-12 col-sm-3 text-right" >
                                <span>{{currentCount}}</span> of <span>{{count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container editor" v-if="editor">

                <form class="form" role="form" autocomplete="off" @submit.prevent="save">
                    <div class="row">
                        <div class="col-8 col-md-8">
                            <b-button class="btn btn-dark mr-2 text-white font-weight-bold" @click="showClients"> Back to Clients</b-button>
                            <!--                            <b-button class="btn btn-dark mr-2 text-white font-weight-bold" @click="showClients">Client Reports</b-button>-->
                            <b-button v-if="item._id" class="btn btn-warning text-white font-weight-bold" :to="{ path: '/product/management', query: { client_id: item._id } }">Product Configuration</b-button>
                            <b-button v-if="item._id" variant="primary" class="font-weight-bold ml-2" @click="modal = true">Add/Verify Domain</b-button>
                            <b-button v-if="item._id" variant="success" class="font-weight-bold ml-2" @click="manageModal = true">Manage Domains</b-button>

                            <b-button v-if="item._id && item.fos === 'pending'" @click="activate(item._id)" style="margin-top:12px;" variant="info" class="font-weight-bold ">Send Activation</b-button>
                            <b-button v-else style="margin-top:12px;" @click="reset(item._id)" variant="danger" class="font-weight-bold">Reset Password</b-button>


                            <verify v-if="modal" :id="item._id" :verify-domain="item.domainVerification" :client_id="item._id" v-on:close="modal = false" v-on:openclose="manageModal = true; modal = false;"/>
                            <manage v-if="manageModal" :client_id="item._id" :verify-domain="domainVerification"  :id="item._id" v-on:closedm="manageModal = false" />
                        </div>
                        <div class="col-4 col-md-4" v-show="item._id">
                            <b-form-checkbox v-model="status" size="lg" name="check-button" switch>
                                Client Status: <b> {{ item.Status }}</b>
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 col-sm-12">
                            <!--                        <h5 class="border-bottom mt-4 pb-2 mb-3">Company Information</h5>-->


                            <h5 class="border-bottom pt-4 pb-2 mb-3">Company Information</h5>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Company Name</label>
                                        <input type="text" v-model="item.Name" autocomplete="off" class="form-control required" required/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Company Email</label>
                                        <input type="email" v-model="item.Email" autocomplete="off" class="form-control required" required/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Company Phone</label>
                                        <vue-tel-input v-model="item.PhoneNumber" :validCharactersOnly="true" :inputOptions="{ required: true, autocomplete: 'off', maxlength: 25, placeholder: 'Enter a phone number', type: 'tel' }" :defaultCountry="ccode" :key="ccode" class="form-control"></vue-tel-input>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Country</label>
                                        <select v-model="item.Country" @change="Getstates" class="form-control">
                                            <option value="" selected>-- Any Country --</option>
                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="form-group" v-if="states">
                                        <label>State / Province</label>
                                        <select required v-model="item.State" @change="Getcities" class="form-control">
                                            <option value="" selected>-- Any State --</option>
                                            <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="form-group">
                                        <label>City</label>
                                        <select required v-model="item.City" class="form-control">
                                            <option value="" selected>-- Any City --</option>
                                            <option v-for="city in cities" :key="city._id" :value="city.city_name">{{city.city_name}}</option>
                                        </select>
                                    </div>
                                </div>



                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Street Address 1</label>
                                        <input type="text" v-model="item.AddressLineOne" class="form-control required" required/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Street Address 2</label>
                                        <input type="text" v-model="item.AddressLineTwo" class="form-control required"/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group" v-if="item.Country !== 'US'">
                                        <label>Street Address  3</label>
                                        <input type="text" v-model="item.AddressLineThree" class="form-control required"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Zip / Postal Code</label>
                                        <input type="text" v-model="item.zip" class="form-control required" required/>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Time Zone</label>
                                        <b-form-select v-model="item.TimeZone" :required="true" class="form-control text-capitalize">
                                            <b-form-select-option value="" disabled>Time Zone</b-form-select-option>
                                            <b-form-select-option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Turn Domain Verification Testing On/Off</label>
                                        <b-form-checkbox v-model="domainVerification" name="check-button" switch class="pt-2">
                                            <p v-if="domainVerification">On</p>
                                            <p v-if="!domainVerification">Off</p>
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>


                            <!--                   end here-->


                            <div v-if="item._id" class="row">
                                <h5 class="border-bottom pt-4 pb-2 mb-3 col-12"> Upload Required Documents</h5>
                                <div id="dropArea" class="col-4">
                                    <ejs-uploader ref="uploadObj" id='defaultfileupload' :multiple="true" name="files" :selected="onFileSelect" :template="fileTemplate" :removing="onFileRemove"></ejs-uploader>
                                </div>

                                <div v-if=" item && item.files" class="col-4">
                                    <h6 class="border-bottom pt-4 pb-2 mb-3">Uploaded Files</h6>
                                    <div class="row" v-for="file in item.files" :key="file._id">
                                        <div class="col-6 col-md-6">
                                            <a href="#" @click="getFiles(file.Id)">{{ file.Name }} - {{ file.Description }}</a>
                                        </div>
                                        <div>
                                            <b-icon-trash variant="danger" v-b-tooltip.hover title="Delete File" font-scale="1.5" style="cursor: pointer" @click="deleteFile(file.Id)"></b-icon-trash>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4 mt-3">
                                    <b-button v-text="'Upload File'" @click="saveFile(item._id)" />
                                </div>
                            </div>


                            <div class="row" v-for="(adCon, idx) in item.adContacts" :key="adCon._id">
                                <h5 class="col-12 border-bottom pt-5 pb-2 mb-3">Client Contact Information <b-button v-text="'Add'" @click="addContacts" pill size="sm" variant="success" /> </h5>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Full Name</label>
                                        <input type="text" v-model="adCon.fullname" class="form-control required"/>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" v-model="adCon.email" class="form-control required"/>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <vue-tel-input v-model="adCon.phone" :defaultCountry="ccode" :validCharactersOnly="true" :key="ccode" class="form-control"></vue-tel-input>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label>Role</label>
                                        <select v-model="adCon.role" class="form-control text-capitalize">
                                            <option value="" disabled>Role</option>
                                            <option v-for="(timezone, index) in dropdownClientsRoles" :key="index">{{ timezone.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1 pl-0 d-flex align-items-center justify-content-center">
                                    <div class="form-group m-0">
                                        <i v-if="item.adContacts.length !== 1" class="fa fa-times-circle" @click="removeCon(idx)" style="font-size: 16pt; cursor: pointer;"></i>
                                    </div>
                                </div>
                            </div>

                            <hr/>
                            <div class="row" v-if="item._id">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Date Created</label>
                                        <p>{{ item.created_ts|date_format_with_time }}</p>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Date Updated</label>
                                        <p>{{ item.modified_ts|date_format_with_time }}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </form>
            </div>

            <div class="row mt-5 d-flex justify-content-center align-content-center" v-if="editor">
                <div class="col-5">
                    <div class="form-group text-center">
                        <button type="submit" @click='save' class="btn btn-success btn-block"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                        <button type="button" class="btn btn-secondary mt-1 btn-sm mr-2" @click="cancel"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                    </div>
                </div>
            </div>
        </div>




        <footerDesign v-if="editor" first-button="Add a Product" first-button-show="false"  second-button="Manage Domains"
                      second-button-show="false" third-button="Manage Products" third-button-show="false">

        </footerDesign>
    </div>



</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import vPagination from 'vue-plain-pagination'
import {BIconTrash} from 'bootstrap-vue'
import moment from 'moment-timezone'
import Vue from 'vue';
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { detach } from '@syncfusion/ej2-base';
import Verify from "@/components/domain/verification/verify";
import Manage from "@/components/domain/management/manage";
import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";

let demoVue = Vue.component("demo", {
    template: `<div class='container'>
    <span class='wrapper'>
                    <span :class="['icon sf-icon-' +data.type]"></span>
                    <span class='name file-name'>{{data.name}} ( {{data.size}} bytes)</span>
        <!--                    <span class='upload-status'>{{data.status}}</span>-->
                    <span class='e-icons e-file-remove-btn' title='Remove'></span>
                    </span>
    <div class="row mt-1">
        <div class="col-5">
            <div class="form-group">
                <!--                                <label>Description</label>-->
                <input :id="data.id" type="text" placeholder="Enter Description" v-model="data.description" class="form-control"/>
            </div>
        </div>
    </div>
    </div>`,
    mounted: function () {
        document.getElementById('dropArea').onclick = (args) => {
            if (args.target.classList.contains('e-file-remove-btn')) {
                document.querySelector("#defaultfileupload").ej2_instances[0].remove(document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData())
                detach(args.target.closest('li'));
            }
        };
    },
    data() {
        return {
            data: {

            },
            value: ''
        };
    },
    watch: {
        "data.description": {
            deep: true,
            handler() {
                this.changeDescription()
            }
        }
    },
    methods: {
        activate: function(id){
            console.log(id);
            return axios.post(`${this.$root.serverUrl}/admin/clients/sendactivation/${id}`).then(function(resp){
                //Store the stats
                console.log(resp.data);
                this.item.fos = "sent";
            }.bind(this));
        },
        changeDescription() {
            for (let i = 0; i < document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData().length; i++) {
                if (this.data.name === document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData()[i].name) {
                    document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData()[i].description = this.data.description;
                }
            }
            console.log(document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData())
        },

    }
});

Vue.use(VueTelInput);
Vue.use(UploaderPlugin);
Vue.use(MultiSelectPlugin);

export default {
    name: 'Clients',
    props:['user'],
    components: {
        Verify,
        vPagination,
        Manage,
        footerDesign,
        headerDesign,
        BIconTrash
    },
    data: function () {
        return {
            value: 'active',
            ccode: '',
            domainVerification:false,
            filters: {
                status:'',
                search:''
            },
            fileTemplate: function (){
                return { template: demoVue }
            },
            description: null,
            dropdownClientsRoles: [],
            timezones: moment.tz.names(),
            fields : { text: 'Product', value: 'Id' },
            allowCustomValue : true,
            path:  {
                saveUrl: `${this.$root.serverUrl}/admin/clients`,
                removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
            },
            count: 0,
            modal: false,
            manageModal: false,
            countries: [],
            states: [],
            cities: [],
            switchStatus: false,
            currentCount:0,
            item: {
                domainVerification:'',
                TimeZone: '',
                Status: '',
                Country: '',
                State: '',
                City: '',
                adContacts: [],
                dateCreated:'',
                dateUpdated:''
            },
            items: {total:0, data:[]},
            editor: false,
            filter: {name:'', Status: 'active'},
            roles:[],
            max: 25,
            page: 1,
            files: [],
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            }
        }
    },
    created() {
        this.$root.preloader = false;
        this.loadcountries();
        this.GetClientsRoles();
        if ( this.item.adContacts.length === 0 || this.item.adContacts === undefined ) this.addContacts()
        if ( this.item.Country === '' ) {
            this.item.Country = 'US';
            this.Getstates();
        }

        if(this.$route.query.edit == 1 ) {
            this.editorOption(this.$route.query.id)
        }


    },
    computed: {
        country() {
            return this.item.Country
        },
        status: {
            get()  {
                return this.item.Status === 'active'
            },
            set(value) {
                this.switchStatus = value
                if (value) {
                    this.activateClient()
                    this.item.Status = 'active'
                } else {
                    this.deactivateClient()
                    this.item.Status = 'disabled'
                }
            }
        },


        columnFields() {
            return [
                {
                    key: '_id',
                    label: 'ID',
                    sortable: true
                },
                {
                    key: 'Name',
                    label: 'Client Name',
                    sortable: true
                },
                {
                    key: 'Country',
                    label: 'Country',
                    sortable: true
                },
                {
                    key: 'State',
                    label: 'State',
                    sortable: true
                },
                {
                    key: 'City',
                    label: 'City',
                    sortable: true
                },
                {
                    key: 'City',
                    label: 'Time Zone',
                    sortable: true
                },
                {
                    key: 'client_campaign',
                    label: '# Of Campaigns',
                    sortable: true
                },
                {
                    key: 'track',
                    label: '# Of Clicks Delivered (Today)',
                    sortable: true
                },
                {
                    key: 'Status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
    },

    watch: {
        country: function(value) {
            this.$nextTick(() => {
                this.ccode = value
            })
        },
        editor: function(value) {
            if (value) {
                if ( this.item.State ) {
                    this.Getcities()
                }
            }
        }
    },
    methods: {
        reset: function(id){
            console.log(id);
            return axios.post(`${this.$root.serverUrl}/admin/clients/sendreset/${id}`).then(function(resp){
                //Store the stats
                console.log(resp.data);
                this.item.fos = "reset";
                swal({title: 'Success', text: "Client password reset sent", icon: 'success'})
            }.bind(this)).catch((err)=>{
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        },
        activate: function(id){
            console.log(id);
            return axios.post(`${this.$root.serverUrl}/admin/clients/sendactivation/${id}`).then(function(resp){
                //Store the stats
                console.log(resp.data);
                swal({title: 'Success', text: "Client account activation email sent", icon: 'success'})
                this.item.fos = "sent";
            }.bind(this)).catch((err)=>{
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        },
        async activateClient() {
            axios.post(`${this.$root.serverUrl}/admin/clients/enable/${this.item._id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: User status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Success`, {
                        title: <strong>Warning: User status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            })
        },
        deactivateClient() {
            axios.post(`${this.$root.serverUrl}/admin/clients/disable/${this.item._id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: User status not modified.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(`Success`, {
                        title: <strong>Success: User status modified.</strong>,
                        variant: 'success',
                        solid: true
                    })
                }
            })
        },
        showClients(){
            this.editor = false
            console.log(this.items.data.length)
        },
        editorOption:function(id){
            axios.get(`${this.$root.serverUrl}/admin/clients/${id}`).then(data => {
                this.edit(data.data.data)
            })
        },
        show() {
            console.log(this.$refs.uploadObj)
        },
        onFileUpload:function(args) {
            // add addition data as key-value pair.
            args.customFormData = [this.args];
            args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + this.$cookies.get('accessToken'))
        },
        addContacts() {
            this.item.adContacts.push({
                fullname: '',
                email: '',
                phone: '',
                role: 'AdminUser'
            })
        },
        removeCon(index) {
            this.item.adContacts.splice(index, 1)
        },
        getFiles(id) {
            window.open(`${this.$root.serverUrl}/admin/files/${id}`, `_blank`)
        },
        loadcountries: function(){
            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        GetClientsRoles: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/roles/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClientsRoles = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        Getstates: function(){
            this.states = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.item.Country) request.filters.country_iso_code = this.item.Country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        Getcities: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.item.Country) request.filters.country_iso_code = this.item.Country;
            if(this.item.State) request.filters.subdivision_1_iso_code = this.item.State;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        create: function() {
            this.filter = {};
            this.item = {
                TimeZone: 'US/Eastern',
                Status: 'active',
                State: '',
                City: '',
                Country: 'US',
                adContacts: []
            };
            this.Getstates();
            this.addContacts()
            this.editor = true;
            this.item.Status === 'active' ? this.switchStatus = true : this.switchStatus = false
        },
        validateEmail() {
            const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            for ( let i = 0; i < this.item.adContacts.length; i++ ) {
                if ( !reg.test(this.item.adContacts[i].email) ) {
                    this.$bvToast.toast('An invalid email was entered in Additional Contacts.', {
                        title: <strong>Warning: Invalid Email</strong>,
                        variant: 'warning',
                        solid: true
                    })
                    return false
                } else {
                    return true
                }
            }

            if (!reg.test(this.item.Email)) {
                this.$bvToast.toast('An invalid email was entered.', {
                    title: <strong>Warning: Invalid Email</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else { return true }
        },
        invalidChar(text) {
            let reg = /^[A-Za-z0-9-. ]+$/
            return reg.test(text)
        },
        validateFields() {
            if ( !this.invalidChar(this.item.Name) ) {
                this.$bvToast.toast('An invalid character was entered.', {
                    title: <strong>Warning: Invalid Name</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else if ( !this.invalidChar(this.item.AddressLineOne) || !this.invalidChar(this.item.AddressLineTwo) || !this.invalidChar(this.item.AddressLineThree) ) {
                this.$bvToast.toast('An invalid character was entered.', {
                    title: <strong>Warning: Invalid Address</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        validateAdCon() {
            if ( this.item.adContacts.length === 0 ) {
                this.$bvToast.toast('Additional Contacts information is needed.', {
                    title: <strong>Warning: Missing additional contact information</strong>,
                    variant: 'warning',
                    solid: true
                })
                return false
            } else {
                return true
            }
        },
        save: function() {
            if ( this.validateFields() ) {
                this.$root.preloader = true;

                let method = (this.item['_id']) ? 'post' : 'put';
                this.item.domainVerification = this.domainVerification
                axios[method](`${this.$root.serverUrl}/admin/clients`, this.item ).then((resp) => {
                    console.log(resp)

                    if(resp.data && !resp.data.error) {
                        //Saved successfully, now update the parent
                        swal({
                            title: "Notice",
                            text: "Your changes were saved successfully",
                            icon: "success"
                        }).then(function (val) {
                            if (val) {
                                this.editor = false;
                                this.item = {}
                                this.search();
                                this.getClients(resp.data.data.id)

                            }
                        }.bind(this));
                    } else {
                        //Handle errors
                        this.errormsg = (resp.data && resp.data.error)? resp.data.error : false;
                        this.$root.preloader = false;
                        swal({title: 'Oops', text: resp.data.error.message, icon: 'error'})
                    }
                })
            }
        },
        getClients(id) {
            let request = {'apikey': this.$root.apikey, filters: this.filters};

            return axios.get(`${this.$root.serverUrl}/admin/clients/${id}`, {params:request}).then((resp) => {
                const result = resp.data.data || []
                this.edit(result)
                this.editor = true;
            })
        },
        saveFile(id) {
            const formData = new FormData();
            // formData.append("args", JSON.stringify(this.item));

            let files = document.querySelector("#defaultfileupload").ej2_instances[0].getFilesData();

            for(let i = 0; i < files.length; i++) {
                formData.append("tags", JSON.stringify({id: files[i].name, description: files[i].description}));
                formData.append("files", files[i].rawFile);
            }
            let method = (this.item['_id']) ? 'post' : 'put';
            axios[method](`${this.$root.serverUrl}/admin/clients/upload/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data'} }).then((resp) => {
                //Process the results
                this.$root.preloader = false;

                if(resp.data && !resp.data.error){
                    //Saved successfully, now update the parent
                    swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                        if(val) {
                            this.editor = false;
                            this.item = {}
                            this.search();
                        }
                    }.bind(this));
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error)? resp.data.error : false;
                    this.$root.preloader = false;
                    swal({title: 'Oops', text: resp.data.error.message, icon: 'error'})
                }
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message||"An unexpected error occured";
                swal({title:"Error", text: this.errormsg, icon:"error"});
            })
        },
        deleteFile(id) {
            axios.delete(`${this.$root.serverUrl}/admin/clients/upload/${this.item._id}/${id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast('File was not removed.', {
                        title: <strong>Error: Deleting File</strong>,
                        variant: 'error',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast('File was removed successfully.', {
                        title: <strong>Success: File Deleted</strong>,
                        variant: 'success',
                        solid: true
                    })
                    this.editor = false;
                    this.item = {}
                    this.search();
                }
            })
        },
        remove: function(obj){
            //Handle removing the item
            swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then(function(val){
                if(!val) return;

                var request = {'apikey': this.$root.apikey, id:obj._id};
                this.$root.preloader = true;

                //Todo handle multiple remove/delete items at once by sending the id as a hiphen separated list
                axios({url:`${this.$root.serverUrl}/admin/clients/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false;
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Remove", text:"Your request was completed successfully", icon:"success"}).then(function(){
                            this.search();
                        }.bind(this));
                    } else {
                        //Handle errors
                        swal({title:"Remove", text:"Unable to complete your request", icon:"error"});
                    }
                }.bind(this))
                    .catch(function(err){
                        this.$root.preloader = false;
                        this.errormsg = err.message||"An unexpected error occured";
                        swal({title:"Error", text: this.errormsg, icon:"error"});
                    }.bind(this))
            }.bind(this));
        },
        edit: function(obj){
            //Edit the vendor item
            if(!obj.adContacts) obj.adContacts =[{
                fullname: '',
                email: '',
                phone: '',
                role: ''
            }];
            this.editor = true;
            this.item = Object.clone({}, obj);
            this.domainVerification = this.item.domainVerification ? this.item.domainVerification : false

            this.$nextTick(() => { this.item.Status === 'active' ? this.switchStatus = true : this.switchStatus = false })
        },
        cancel: function(){
            this.item = { };
            this.editor = false;
            this.errormsg = false;
        },
        search: function(){
            //Load the items
            if(this.page == 0) {
                this.page = 1;
            }
            var request = { apikey: this.$root.apikey, sSearch: null, filters: {}, max: this.max, page: this.page };
            if (this.filters.search)request.filters.search = this.filters.search;
            if (this.filters.key) request.filters.key = this.filters.key;
            if (this.filters.status) request.filters.Status = this.filters.status;
            // request.filters.page = page;
            // request.filters.max = this.max;
            //   this.$root.preloader = true;
            axios
                .get(`${this.$root.serverUrl}/admin/clients/getWithCampaigns`, {
                    params: request,
                })
                .then(
                    function (resp) {

                        if(resp.data.data){
                            this.items = { total: resp.data.data.length, data: resp.data.data }
                            this.count = resp.data.count

                            this.currentCount = this.max * this.page

                            if(this.currentCount % this.max != 0 || this.currentCount > this.items.total) {
                                this.currentCount = this.max * (this.page-1)
                                this.currentCount+=this.items.data.length
                            }

                            this.$root.preloader = false;
                        }else{
                            this.items = {total: 0, data: ''}
                            this.$root.preloader = false;
                        }

                    }.bind(this)
                );

        },
        onFileSelect: function(args) {
            console.log(args)
        },
        onFileRemove(args) {
            console.log(args)
        }
    }
}
</script>

<style scoped>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";


.btnSearch span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
.site-footer
{
    background-color:#26272b;
    padding:45px 0 20px;
    font-size:15px;
    line-height:24px;
    color:#737373;
}



@media (max-width:991px)
{
    .site-footer [class^=col-]
    {
        margin-bottom:30px
    }
}
@media (max-width:767px)
{
    .site-footer
    {
        padding-bottom:0
    }
    .site-footer
    {
        text-align:center
    }
}


</style>
