<template>
    <div type="text/x-template" id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="container">
                            <div class="row d-flex justify-content-end">
                                <b-icon-x class="mt-3 mr-3 font-weight-bold" style="cursor: pointer;" @click="$emit('close')"></b-icon-x>
                            </div>
                        </div>

                        <div v-if="state === 'add'">
                            <div class="modal-header d-flex justify-content-center">
                                <h5 class="m-0"><strong>Add Domain</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5">
                                <b-form-input style="width: 100%" @keyup="validateDomain(name)" :style="{ 'border-color': color, 'color': color }" v-model="name" class="border-top-0 border-left-0 border-right-0"></b-form-input>
                                <small>Example: example.com, sub.example.com</small>
                            </div>

                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('close')">CANCEL</p></div>
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="confirmDomain">ADD/VERIFY DOMAIN</p></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state === 'verify'">
                            <div class="modal-header d-flex justify-content-center">
                                <h5 class="m-0"><strong>Verify Domain (Choose Method)</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5">
                                <b-form-input style="width: 100%" disabled class="border-top-0 border-left-0 border-right-0"></b-form-input>
                                <small>Example: example.com, sub.example.com</small>

                                <div class="container">
                                    <div class="row mt-2">
                                        <div class="col-12 d-flex justify-content-center">
                                            <b-form-group>
                                                <b-form-radio v-model="method" name="verification_method" value="txt">Upload a .txt file</b-form-radio>
                                                <b-form-radio v-model="method" name="verification_method" value="cname">Create a CNAME record</b-form-radio>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('close')">CANCEL</p></div>
                                    <div v-if="method === 'txt'" class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="state = 'method_file'">DOWNLOAD FILE</p></div>
                                    <div v-if="method === 'cname'" class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="state = 'method_dns'">GENERATE CNAME RECORD</p></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state === 'method_file'">
                            <div class="modal-header d-flex justify-content-center">
                                <h5 class="m-0"><strong>TXT Verification File</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5">
                                <div class="container">
                                    <div class="row mt-2">
                                        <p>To verify that you own {{ domain }}, follow the instructions to add TXT file to your domain settings.</p>
                                        <p>A TXT file is just a value that we look for to confirm that own {{ domain }}. Adding this won't affect your current email or website.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
<!--                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('close')">CANCEL</p></div>-->
                                    <div class="col-12 col-md-12 d-flex justify-content-center"><p style="cursor: pointer;" >DOWNLOAD FILE</p></div>
                                    <div class="col-12 col-md-12 d-flex justify-content-center"><small style="cursor: pointer; color: deepskyblue;" v-b-toggle.txt_help>Learn How</small></div>
                                    <b-collapse id="txt_help">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12">
                                                    <p>To verify that you own {{ domain }}, follow the instructions to add TXT file to your domain settings.</p>
                                                    <p>A TXT file is just a value that we look for to confirm that own {{ domain }}. Adding this won't affect your current email or website.</p>
                                                    <p>To verify that you own {{ domain }}, follow the instructions to add TXT file to your domain settings.</p>
                                                    <p>A TXT file is just a value that we look for to confirm that own {{ domain }}. Adding this won't affect your current email or website.</p>
                                                    <p>To verify that you own {{ domain }}, follow the instructions to add TXT file to your domain settings.</p>
                                                    <p>A TXT file is just a value that we look for to confirm that own {{ domain }}. Adding this won't affect your current email or website.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <div v-if="state === 'method_dns'" >
                            <div class="modal-header d-flex justify-content-center">
                                <h5 class="m-0"><strong>DNS Verification</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5">
                                <div class="container">
                                    <div class="row mt-2">
                                        <div class="col-11">
                                            <b-form-input style="width: 100%; font-size: small;" v-model="dns" disabled class="border-top-0 border-left-0 border-right-0"></b-form-input>
                                            <small v-if="copied" style="color: #28a745">Content Copied!</small>
                                        </div>
                                        <div class="col-1 d-flex justify-content-center align-content-center pl-0 pt-2">
                                            <b-icon-files font-scale="1.5" style="cursor: pointer" @click="copy"></b-icon-files>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
<!--                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('close')">CANCEL</p></div>-->
                                    <div class="col-12 col-md-12 d-flex justify-content-center"><p style="cursor: pointer;" @click="state = 'check'">CHECK STATUS</p></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state === 'check'">
                            <div class="modal-header d-flex justify-content-center">
                                <h5 class="m-0"><strong>Domain Check Result</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5 d-flex justify-content-center">
                                <div class="container" v-if="domain_id">

                                    <div class="row" v-if="domain_hash_data.status === 'verified'">
                                        <div class="col-6 d-flex justify-content-center">
                                            <p>{{ domain_hash_data.name }}</p>
                                        </div>
                                        <div class="col-6">
                                            <p><b-icon-patch-check-fill variant="success"></b-icon-patch-check-fill> verified</p>
                                        </div>
                                    </div>

                                    <div class="row" v-if="domain_hash_data.status === 'pending'">
                                        <div class="col-6 d-flex justify-content-center">
                                            <p>{{ domain_hash_data.name }}</p>
                                        </div>
                                        <div class="col-6">
                                            <p><b-icon-patch-question-fill variant="warning"></b-icon-patch-question-fill> pending <small style="cursor: pointer; color: deepskyblue;" @click="verify(domain_hash_data._id)"> Try Again</small></p>
                                        </div>
                                    </div>

                                    <div class="row" v-if="domain_hash_data.status === 'unverified'">
                                        <div class="col-6 d-flex justify-content-center">
                                            <p>{{ domain_hash_data.name }}</p>
                                        </div>
                                        <div class="col-6 ">
                                            <p><b-icon-patch-minus-fill variant="danger"></b-icon-patch-minus-fill> un-verified <small style="cursor: pointer; color: deepskyblue;"> Try Again</small></p>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 d-flex justify-content-center">
                                            <p class="mb-1">You can <strong> Add </strong> another domain or <strong style="color: red"> Cancel</strong>.</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <small>Please note: Domain verification may take up to 24 hours.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('openclose')">MANAGE DOMAINS</p></div>
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$router.push({ path: '/product/management', query: { client_id: id } })">CHOOSE A PRODUCT</p></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { BIconX, BIconPatchCheckFill, BIconPatchMinusFill, BIconPatchQuestionFill, BIconFiles } from 'bootstrap-vue'
    import VueClipboard from 'vue-clipboard2'
    import axios from "axios";
    Vue.use(VueClipboard)

    export default {
        name: "verify",
        data() {
            return {
                name: '',
                state: 'add',
                domain_hash_data: '',
                method: '',
                domain_id: '',
                color: '',
                copied: false,
                dns: '',
            }
        },
        props: {
            id: {
                type: String,
                required: true,
            }
        },
        components: {
            BIconX,
            BIconPatchCheckFill,
            BIconPatchMinusFill,
            BIconPatchQuestionFill,
            BIconFiles,
        },
        methods: {
            copy() {
                this.$copyText(this.dns).then(() => {
                    this.copied = true
                })
            },
            validateDomain(dom) {
                let reg = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

                if (!reg.test(dom)) {
                    this.$nextTick(() => { this.color = 'red' })
                    return false
                } else {
                    this.$nextTick(() => { this.color = 'green' })
                    return true
                }
            },
            verify(id) {
                axios.post(`${this.$root.serverUrl}/admin/domain/domain/verify/${id}`).then((resp) => {
                    if ( resp.data && !resp.data.error ) {
                        this.testVerify(id)
                    }
                })
            },
            testVerify(id) {
                axios.get(`${this.$root.serverUrl}/admin/domain/domain/${id}`).then((resp) => {
                    if ( resp.data && !resp.error ) {
                        this.dns = resp.data.data.hash
                        this.domain_hash_data = resp.data.data
                        this.state = 'check'
                    }
                })
            },
            confirmDomain() {
                let payload = {
                    client_id: this.id,
                    name: this.name
                }

                if ( this.validateDomain(payload.name) ) {
                    axios.put(`${this.$root.serverUrl}/admin/domain/domain`, payload).then((resp) => {
                        if ( resp.data && !resp.error ) {
                            this.domain_id = resp.data.data._id
                        }
                    }).finally(() => { this.dnsMethod(this.domain_id) })
                }
            },
            dnsMethod(id) {
                axios.get(`${this.$root.serverUrl}/admin/domain/domain/${id}`).then((resp) => {
                    if ( resp.data && !resp.error ) {
                        this.dns = resp.data.data.hash
                        this.domain_hash_data = resp.data.data
                        this.state = 'method_dns'
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .form-control:focus {
        -webkit-box-shadow: 0 0 0 0rem;
        box-shadow: 0 0 0 0rem;
        border-color: #CED4DA;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: table;
        transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -webkit-transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 600px;
        margin: 0px auto;
        padding: 0px 0px 20px 0px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-leave-active .modal-container {
        transform: scale(1.1);
        -o-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }

</style>
